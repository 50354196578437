<template>
  <div class="d-flex justify-end w-full" :class="`flex-${footerFlexDirection}`">
    <v-expansion-panels class="mb-2 mr-2">
      <v-expansion-panel>
        <v-expansion-panel-header class="text-body-2 font-weight-semibold">
          Правила передачи показаний
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list class="text-body-2 text-left">
            <v-list-item
              v-for="rule in sendingRules"
              :key="rule"
              v-html="rule"
            ></v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import CountersService from "../../../services/CountersService";

export default {
  name: "CountersCardFooter",
  data() {
    return {
      sendingRules: CountersService.sendingRules,
    };
  },
  computed: {
    footerFlexDirection() {
      return this.$vuetify.breakpoint.xs ? "column" : "row";
    },
  },
};
</script>

<style scoped>
.v-expansion-panel-header {
  min-height: unset;
  height: 38px; /*высота кнопки во vuetify*/
}
</style>

<template>
  <simple-card>
    <template v-slot:title-left>Показания</template>
    <template v-slot:content>
      <v-row v-if="needLoadingIndicator">
        <counter-card
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="3"
          v-for="n in 3"
          :key="n"
          :loader="true"
        ></counter-card>
      </v-row>
      <v-row v-else-if="counters.length">
        <counter-card
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="3"
          v-for="(counter, index) in counters"
          :key="`${counter.name}_${index}`"
          :counter="counter"
        ></counter-card>
      </v-row>
      <v-row v-else>
        <v-col cols="12 text-h6 text-align-center"
          >Нет счетчиков для отображения</v-col
        >
      </v-row>
    </template>
    <template v-slot:footer>
      <counters-card-footer></counters-card-footer>
    </template>
  </simple-card>
</template>

<script>
import SimpleCard from "@/components/cards/SimpleCard";
import CounterCard from "./CounterCard";
import CountersCardFooter from "../../views/counters/components/CountersCardFooter";
import CountersService from "../../services/CountersService";
import { mapState } from "vuex";
import loadingIndicator from "../../shared/mixins/loadingIndicator";

export default {
  name: "CountersCard",
  components: { CountersCardFooter, SimpleCard, CounterCard },
  mixins: [loadingIndicator],
  data() {
    return {
      loadingFlag: "get_measures",
    };
  },
  async mounted() {
    await CountersService.getCounters();
  },
  computed: {
    ...mapState("account", ["counters"]),
    ...mapState("user", ["activeBinding"]),
  },
  watch: {
    activeBinding() {
      CountersService.getCounters();
    },
  },
};
</script>

<style lang="scss" scoped></style>

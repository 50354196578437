<template>
  <v-skeleton-loader
    height="165px"
    v-if="loading"
    type="image"
    v-bind="$attrs"
  ></v-skeleton-loader>
  <simple-card v-else :need-footer="false">
    <template v-slot:title-left>История показаний</template>
    <template v-slot:content>
      <v-tabs show-arrows v-model="tab">
        <v-tabs-slider></v-tabs-slider>

        <v-tab
          v-for="(counter, i) in indicationsByCounter"
          :key="'tab_counter_' + i"
        >
          <v-img v-if="counter.img" class="mr-1" :src="counter.img" width="13">
          </v-img>
          <v-img
            v-else
            class="mr-1"
            src="../../assets/icons/default_counter.svg"
            width="13"
          >
          </v-img>
          {{ counter.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(counter, i) in indicationsByCounter"
          :key="'tab_indications_' + i"
        >
          <simple-table
            :headers="headers"
            :use-custom-rows="true"
            :rows="counter.rows"
          >
            <template v-slot:table-row="{ item }">
              <CounterValueRow :item="item"></CounterValueRow>
            </template>
          </simple-table>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </simple-card>
</template>

<script>
import CountersService from "../../services/CountersService";
import { mapState } from "vuex";
import SimpleTable from "../tables/SimpleTable";
import SimpleCard from "./SimpleCard";
import CounterValueRow from "@/components/CounterValueRow";

export default {
  name: "CountersHistoryCard",
  components: {
    CounterValueRow,
    SimpleTable,
    SimpleCard,
  },
  async mounted() {
    this.loading = true;
    await CountersService.getIndicationsHistory();
    this.loading = false;
  },
  data() {
    return {
      headers: CountersService.historyHeaders,
      loading: false,
      tab: 0,
    };
  },
  computed: {
    ...mapState("account", ["indications"]),
    ...mapState("user", ["activeBinding"]),
    indicationsByCounter() {
      return CountersService.getIndicationsDataByCounter(this.indications);
    },
  },
  watch: {
    async activeBinding() {
      this.loading = true;
      await CountersService.getIndicationsHistory();
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>

<template>
  <page-layout>
    <v-col cols="12">
      <counters-card></counters-card>
    </v-col>
    <v-col cols="12">
      <counters-history-card></counters-history-card>
    </v-col>
  </page-layout>
</template>

<script>
import CountersCard from "@/components/cards/CountersCard";
import CountersHistoryCard from "../../components/cards/CountersHistoryCard";
import PageLayout from "../../components/Layouts/PageLayout";

export default {
  name: "Counters",
  components: { CountersHistoryCard, CountersCard, PageLayout },
};
</script>

<style scoped></style>

<template>
  <tr>
    <td>{{ formatDate }}</td>
    <td>{{ formatCounter }}</td>
    <td>{{ formatValue }}</td>
    <td>
      <template v-if="isResultText">{{ formatResult }}</template>
      <v-icon v-else>mdi-check</v-icon>
    </td>
  </tr>
</template>

<script>
import { getFormattedDate } from "@/shared/helpers/date";

export default {
  name: "CounterValueRow",
  props: {
    item: Object,
  },
  computed: {
    formatDate() {
      return getFormattedDate(this.item?.date, "L") || "-";
    },
    formatCounter() {
      return this.item?.counter || "-";
    },
    formatValue() {
      return this.item?.value || "-";
    },
    isResultText() {
      return this.formatResult !== "accepted";
    },
    formatResult() {
      return this.item?.result || "";
    },
  },
};
</script>

<style scoped></style>
